import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, generatePath } from "react-router-dom";
import { routes } from "../routes";
import { useParams } from "react-router-dom";
import logo from "../images/cmv-logo.svg";
// import LanguageSwitcher from "./language-switcher";

export default function Nav() {
  let { brand } = useParams();
  const { t, i18n } = useTranslation();
  const [navbarOpen, setNavbarOpen] = useState(false);
  const isConfPage = window.location.href.indexOf("confirmation") > -1

  var menuToggle = document.getElementById( 'menu-toggle' );

  function toggleNav(){
    navbarOpen == false ? setNavbarOpen(true): setNavbarOpen(false)
        // Toggle class "opened". Set also aria-expanded to true or false.
        if ( -1 !== menuToggle.className.indexOf( 'opened' ) ) {
          menuToggle.className = menuToggle.className.replace( ' opened', '' );
          menuToggle.setAttribute( 'aria-expanded', 'false' );
        } else {
          menuToggle.className += ' opened';
          menuToggle.setAttribute( 'aria-expanded', 'true' );
        }
  }
var thisPath = window.location.pathname
if (thisPath == "/"){
  var activeLink = "home";
} else if (thisPath == "/en"){
  var activeLink = "home";
} else if (thisPath == "/es"){
  var activeLink = "home";
} else if (thisPath == "/home/en"){
  var activeLink = "home";
} else if (thisPath == "/home/es"){
  var activeLink = "home";
} else if (thisPath.includes("/what-is-cmv")){
  var activeLink = "what";
} else if (thisPath.includes("/about-the-study")){
  var activeLink = "about";
} else if (thisPath.includes("/information-for-centers")){
  var activeLink = "centers";
} else if (thisPath.includes("/meet-team")){
  var activeLink = "meet";
} else if (thisPath.includes("/contact-us")){
  var activeLink = "contact";
} else if (thisPath.includes("/updates")){
  var activeLink = "updates";
} else if (thisPath.includes("/confirmation")){
  var activeLink = ""
} else { 
  var activeLink = "updates" 
}

  return (
    <header>
            {/* <LanguageSwitcher /> */}
      <Link
            to="/"
            rel="home"
            className="header-logo"
          ><img
          src={logo}
          alt="logo"
        /> 
        <span className="tagline">{t("study-name-full")}</span>
      </Link>
      
    </header>
  );
}
