import React, { useEffect, useState } from "react";
import { useParams, Link, generatePath } from "react-router-dom";
import { routes } from "../routes";
import AOS from "aos";
import hero_bk from "../images/kids-sharing.jpg";
import hero_img from "../images/kids-sharing.jpg";
import cmvWhite from "../images/cmv-transmit-white-text.svg";
import kids01 from "../images/kids-with-parents.jpg";
import kids02 from "../images/kids01.jpg";
import wave from "../images/wave-f5f5f5.svg";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
import MyDataHelps from "@careevolution/mydatahelps-js";
import Nav from "../partials/nav";
import CtaModule from "../partials/cta-module";
import Footer from "../partials/footer";
import getBrandConfiguration from "../helpers/getBrandConfiguration"
import {
	useSearchParams
} from "react-router-dom";

export default function Home() {

  let { locale } = useParams();
  const { t, i18n } = useTranslation();

  const [search] = useSearchParams();
  const [shownJoinPopup, setShownJoinPopup] = useState(false);

  const VaxUrl = "https://www.washtenaw.org/3269/COVID-19-Vaccination"
 
  const tagManagerArgs = {
    dataLayer: {
      event: "page_view",
      page_location: document.location.href,
      page_title: t("home-meta-title"),
    },
    };

  var brandConfiguration = getBrandConfiguration();
  function join() {
    console.log("MyDataHelps.startEmbeddedSurvey")
    MyDataHelps.startEmbeddedSurvey(brandConfiguration.projectCode, "Consent").then(function (result) {  if (result.reason === "Completed") {
          //redirect to confirmation page with the link identifier in the url
          const accessKeyObj = result.taskResult.StepResults.find(obj => obj.Identifier === "AccessKey");
          const accessKey = accessKeyObj && accessKeyObj.QuestionResults[0].Result;

          window.location = window.location.origin + "/confirmation/" + (locale ?? "en") + "?participant=" + accessKey;
      }
    });
  }

  TagManager.dataLayer(tagManagerArgs);
  useEffect(() => {
    document.title = t("home-meta-title");
    AOS.init({
      duration : 2000
    });

    if (search.has("join") && !shownJoinPopup) {
      setShownJoinPopup(true);
      console.log(shownJoinPopup);
      setTimeout(join, 1000);
    }
  }, [shownJoinPopup]);
  return (
    <>
    <Nav />
    <main className="home-page">
    

    <section>
      <div></div>      
    </section>

     <CtaModule locale={locale} />
    </main>
    <Footer />
    </>

  );
}
